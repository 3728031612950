<template>
  <div class="field-item-wrapper">
    <div class="label">{{ field.label }}</div>
    <div class="value">{{ field.value }}</div>
  </div>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue'
export default defineComponent({
  name: 'NesiFieldItem',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const field = reactive({ label: props.item.label, value: props.item.value })
    watch(
      () => props.item,
      (newValue, oldValue) => {
        field.value = newValue.value
      }
    )
    return { field }
  }
})
</script>

<style scoped lang="scss">
.field-item-wrapper {
  min-width: 50%;
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  .label {
    color: #909399;
    min-width: 80px;
    text-align: right;
    font-size: 15px;
  }
  .value {
    color: #303133;
    font-size: 15px;
  }
}
</style>
