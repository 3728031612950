<template>
  <div style="height: 100%">
    <nesi-user-common :title="`房源核验`">
      <template #content>
        <div class="container">
          <div class="step-wrapper" finish-status="success">
            <el-steps :active="2" align-center>
              <el-step title="查询权属信息"></el-step>
              <el-step title="填写房屋信息"></el-step>
              <el-step title="发布申请结果"></el-step>
            </el-steps>
          </div>
          <div class="wrapper">
            <nesi-section :title="`房屋信息`">
              <nesi-field-item
                :item="item"
                v-for="item in fields"
                :key="item.id"
              ></nesi-field-item>
            </nesi-section>
            <nesi-section :title="`权利人信息`">
              <nesi-card
                :title="item.qlrxm"
                :isShowIcon="true"
                v-for="item in ownerList"
                :key="item.id"
                :isShow="item.isShow"
              >
                <nesi-field-item
                  :item="JSON.parse(JSON.stringify(field))"
                  v-for="field in item.fields"
                  :key="field.id"
                ></nesi-field-item>
                <!-- <div class="field-item-wrapper">
                  <div class="label">联系电话：</div>
                  <div class="value">联系电话：</div>
                </div>
                <div v-if="item.fields[5].value === '-'" style="width: 100%;">
                  <el-button type="primary" @click="addPhone(item)"
                    >新增手机号码</el-button
                  >
                </div>
                <div v-if="item.fields[5].value === '-'" style="width: 100%;">
                  <el-button type="primary" @click="addPhone(item)"
                    >新增手机号码</el-button
                  >
                </div> -->
              </nesi-card>
            </nesi-section>
            <nesi-section :title="`房屋权属证明(图片、pdf且必填)`">
              <nesi-upload
                @handleFile="handleFile"
                @deleteFile="deleteFile"
              ></nesi-upload>
            </nesi-section>
            <div class="btn-wrapper">
              <el-button type="primary" @click="onSubmit" :disabled="sDisable"
                >提交</el-button
              >
              <el-button @click="preview">上一步</el-button>
            </div>
            <div class="tip-wrapper">
              <div class="title">
                <i class="el-icon-warning-outline mr-2"></i
                >房屋权属证明上传说明指导指南：
              </div>
              <div class="content">
                <p>
                  1.
                  房屋所有权证号：需上传房屋所有权证（即主信息页、证号页、附记页、图纸页等
                </p>
                <p>
                  2.
                  不动产权证号：需上传不动产权证（即主信息页、证号页、附记页、图纸页等）
                </p>
                <p>
                  3.
                  预售合同备案编号：需上传商品房买卖合同登记备案证明、竣工验收材料（或小区物业交付使用入住证明）
                </p>
                <p>
                  4.
                  商品房网签合同编号：需上传商品房预售合同登记备案表、竣工验收材料（或小区物业交付使用入住证明）
                </p>
                <!-- <p>5. 租赁备案证明号：（无）</p> -->
                <p>
                  5.
                  其他证明：需上传国有土地使用证、建设工程规划许可证、竣工验收备案证明等相关权属证明
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </nesi-user-common>
  </div>
</template>
<script setup>
import NesiCard from '@/components/NesiCard/index.vue'
import NesiUpload from '@/components/NesiUpload/index.vue'
import NesiSection from '@/components/NesiSection/index.vue'
import NesiFieldItem from '@/components/NesiFieldItem/index.vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { handleFields } from '@/utils/util'
import { useRoute } from 'vue-router'
defineComponent({
  NesiUserCommon,
  NesiSection,
  NesiFieldItem,
  NesiUpload,
  NesiCard
})
// const router = useRouter()
const route = useRoute()

const house = route.params.house
  ? ref(JSON.parse(route.params.house))
  : ref(null)

const handleFile = (data) => {
  form.fileList.push(data)
}

const deleteFile = (data) => {
  form.fileList = form.fileList.filter((item) => item.uid !== data.uid)
}

const form = reactive({
  applyStatus: '0',
  businessNo: '',
  houseId: '',
  applyID: new Date().getTime(),
  remarks: '',
  subleaseType: 0,
  type: 0,
  warrantNo: '',

  agentType: 0,
  buildingArea: 0,
  district: '',
  houseNumber: '',
  housingUse: '',
  insideArea: 0,
  iuserid: '',
  location: '',
  ptdm: '',
  rightHolderInfos: [
    {
      cardNo: '',
      cardType: '',
      certNo: '',
      certNoType: '',
      contractNo: '',
      name: '',
      orgType: '',
      peopleType: '',
      phoneNo: ''
    }
  ],
  scans: [
    {
      smjmc: '',
      smjsj: ''
    }
  ]
})

const fields = ref([])
const ownerList = ref([])

const defFields = reactive([
  {
    label: '所在城区：',
    value: '',
    name: 'districtName',
    fix: '',
    translate: []
  },
  {
    label: '房屋用途：',
    value: '',
    name: 'housingUse',
    fix: '',
    translate: []
  },
  {
    label: '套内面积：',
    value: '',
    name: 'insideArea',
    fix: '平方',
    translate: []
  },
  {
    label: '建筑面积：',
    value: '',
    name: 'buildingArea',
    fix: '平方',
    translate: []
  },
  { label: '房屋坐落：', value: '', name: 'location', fix: '', translate: [] }
])

let originOwners = []
if (house.value && house.value.qlrInfo) {
  originOwners = house.value.qlrInfo.map((item) => {
    item.phoneNo = ''
    return item
  })
}

const defOwners = reactive([
  { label: '权利类型：', name: 'orgType', value: '', fix: '', translate: [] },
  {
    label: '权利人类型：',
    name: 'peopleType',
    value: '',
    fix: '',
    translate: []
  },
  { label: '权证号：', name: 'certNo', value: '', fix: '', translate: [] },
  { label: '证件类型：', name: 'cardType', value: '', fix: '', translate: [] },
  { label: '证件号码：', name: 'cardNo', value: '', fix: '', translate: [] },
  { label: '联系电话：', name: 'phoneNo', value: '', fix: '', translate: [] }
])

onMounted(() => {
  fields.value = JSON.parse(
    JSON.stringify(handleFields(house.value, defFields))
  )
  originOwners.map((item) => {
    const fields = JSON.parse(JSON.stringify(handleFields(item, defOwners)))
    const qlrxm = item.name
    // const isShow = item.cardNo !== user.value.certNo
    ownerList.value.push({ fields, qlrxm, isShow: false })
  })
})

const sDisable = ref(false)
const onSubmit = () => {}
const preview = () => {}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  // box-shadow: 0 0 50px 5px #e4e7ed;
  .step-wrapper {
    padding: 30px 0;
  }

  .wrapper {
    margin: 20px auto;
    border: solid 1px #e4e7ed;
    padding: 10px 10px;
    border-radius: 5px;
    transition: 0.2s;
    width: 95%;

    .form {
      width: 50%;
      height: 100%;
    }
  }
  .divider {
    width: 100%;
    border-top: 1px solid dotted;
    height: 1px;
  }
}

.tip-wrapper {
  margin-top: 50px;
  color: #ff9900;
  background: #fdf6ec;
  padding: 10px;
  border-radius: 5px;

  .title {
    font-size: 14px;
  }
  .content {
    margin-top: 5px;
    font-size: 13px;

    p {
      height: 20px;
      line-height: 20px;
    }
  }
}
.mr-2 {
  margin-right: 2px;
}

.btn-wrapper {
  margin-top: 40px;
  padding: 0 10px;
}

.breadcrumb {
  padding: 0 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 24px 20px;
  border-bottom: 1px solid #e4e7ed;
}

.tree-wrapper {
  height: 60vh;
  overflow-y: auto;
}

.search {
  display: flex;
  justify-content: center;
  // border-bottom: dotted 1px #e4e7ed;
}

.field-item-wrapper {
  min-width: 50%;
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  .label {
    color: #909399;
    min-width: 80px;
    text-align: right;
    font-size: 15px;
  }
  .value {
    color: #303133;
    font-size: 15px;
  }
}

.field-item-wrapper {
  min-width: 50%;
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  .label {
    color: #909399;
    min-width: 80px;
    text-align: right;
    font-size: 15px;
  }
  .value {
    color: #303133;
    font-size: 15px;
  }
}
</style>
