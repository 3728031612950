<template>
  <div class="card-wrapper">
    <div class="title">
      <div class="left">
        <i
          class="el-icon-user-solid mr-5"
          style="color: #2979ff;"
          v-if="card.isShowIcon"
        ></i>
        {{ card.title }}
      </div>
      <!-- <div class="right" v-if="card.isShow">共有产权人</div> -->
    </div>
    <div class="content">
      <slot></slot>
    </div>
    <div></div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'card',
  props: {
    title: {
      type: String,
      required: true
    },
    isShowIcon: {
      type: Boolean,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props) {
    const card = reactive({
      title: props.title,
      isShowIcon: props.isShowIcon,
      isShow: props.isShow
    })
    return { card }
  }
})
</script>

<style scoped lang="scss">
.card-wrapper {
  width: 48%;
  height: 100%;
  padding: 0 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  // box-shadow: 0 0 20px 5px #e4e7ed;
  border: 1px solid #e4e7ed;

  .title {
    height: 40px;
    line-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e7ed;

    .right {
      background: #fdf6ec;
      color: #ff9900;
      height: 30px;
      line-height: 30px;
      padding: 0 5px;
      font-size: 13px;
      border-radius: 5px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
  }
}

.mr-5 {
  margin-right: 5px;
}
</style>
